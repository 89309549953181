<template>
  <v-dialog v-model="show" persistent :retain-focus="false" max-width="1000">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Edit Centers / Branches</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.center_name" label="Center Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.address" label="Address" required></v-text-field>
              </v-col>
             <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="item.phone_number" 
                  label="Phone No" 
                  required
                  counter="10"
                  @keypress="onlyNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="item.city"
                  label="City"
                  required
                  :items="roleList"
                  item-text="name"
                ></v-autocomplete>
              </v-col>
               <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.area" label="Area" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.contact_person" label="Contact Person" required></v-text-field>
              </v-col> 
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="show = false"> Cancel </v-btn>
          <v-btn color="primary" @click="editBranchesList()"> Save </v-btn>
        </v-card-actions>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-card>
  </v-dialog>
</template>
<script>
import Onecity from '@/service/EyeDonationCenter.service'
import BranchesSevice from '@/service/Branches.service'
export default {
  props: ['visible', 'item'],
  data(){
    return{
      roleList: [{ city_id: "", name: ""}], 
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },

  computed: {
    formIsValid () {
      return (
        this.item.center_name &&
        this.item.address &&
        this.item.phone_number &&
        this.item.area &&
        this.item.contact_person
      )
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
          setTimeout(() => {
            location.reload()
          }, 100)
        }
      },
    },
  },

  watch: {
    options: {
      handler() {
        // this.GetCity()
        this.GetCityList()
      },
      deep: true,
    },
    
  },

  async mounted() {
    this.GetCityList()
  },

  methods:{

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9 ) {
          $event.preventDefault();
      }
    },

    async GetCityList() {
      const service = new Onecity()
      let response = await service.OneCityList()
      if (response) {
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async editBranchesList(){
      if(this.item.phone_number.length  < 10){
        return false,
        this.snackbarText = "Phone number should be 10 character.",
        this.snackbar = true
      }
        try {
          const data ={
              id: this.item.id,
              center_name: this.item.center_name,
              address: this.item.address,
              phone_number: this.item.phone_number,
              city:this.item.city,
              area: this.item.area,
              contact_person: this.item.contact_person,
          }
          const service = new BranchesSevice()
          const response = await service.updatebranches(data)
          if(response.status == 200){
            this.snackbarText = response.message,
            this.snackbar = true
            setTimeout(() => {
              this.show = false
            }, 1000)
          }else{
            this.snackbarText = response.message,
            this.snackbar = true
          }
        } catch (e) {
            console.log(e)
            
        }
    }
  }
}

</script>